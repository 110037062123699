// eslint-disable-next-line import/named
import { headers, removeHeader } from "libs/api/interceptors";

// This is to call the API without any invalid credentials. Since this endpoint is open.
const UNAUTHORIZED_INTERCEPTOR = removeHeader("Authorization");
// This is to override the locale to pass in the header since this endpoint is used in a page
// where there is no i18n wrapper, and then we have no access to the current locale context.
const HEADER_LOCALE_INTERCEPTOR = (locale) => (
  locale
    ? headers({ "Accept-language": locale })
    : (query) => query
);

export default function rawReceipts(client) {
  const path = "/receipts";

  return {
    getTransactionData: (transactionRawData, locale) => client.request({
      endpoint: `${path}/actions`,
      version: "v2",
      body: {
        type: "GET_TRANSACTION_DATA",
        config: {
          transaction_raw_data: transactionRawData,
        },
      },
      method: "POST",
      interceptors: [
        UNAUTHORIZED_INTERCEPTOR,
        HEADER_LOCALE_INTERCEPTOR(locale),
      ],
    }),
    sendByEmail: (transactionRawData, email, formData, locale) => client.request({
      endpoint: `${path}/actions`,
      version: "v2",
      body: {
        type: "SEND_TRANSACTION_RECEIPT_EMAIL",
        config: {
          email_address: email,
          form_data: formData,
          transaction_raw_data: transactionRawData,
        },
      },
      method: "POST",
      interceptors: [
        UNAUTHORIZED_INTERCEPTOR,
        HEADER_LOCALE_INTERCEPTOR(locale),
      ],
    }),
    getPdf: (transactionRawData, formData, locale) => client.request({
      endpoint: `${path}/actions`,
      version: "v2",
      body: {
        type: "GET_TRANSACTION_RECEIPT_PDF_DATA",
        config: {
          form_data: formData,
          transaction_raw_data: transactionRawData,
        },
      },
      method: "POST",
      interceptors: [
        UNAUTHORIZED_INTERCEPTOR,
        HEADER_LOCALE_INTERCEPTOR(locale),
      ],
    }),
  };
}
