// eslint-disable-next-line no-unused-vars
export default function refunds(client, config) {
  const path = `/widgets/${config.widgetUUID}/refund-requests`;
  return {
    create: (data) => client.request({
      endpoint: path,
      method: "POST",
      body: data,
    }),
    get: (id) => client.request({
      endpoint: `${path}/${id}`,
      method: "GET",
    }),
    list: () => client.request({
      endpoint: `${path}`,
      method: "GET",
    }),
    destroy: (id) => client.request({
      endpoint: `${path}/${id}`,
      method: "DELETE",
    }),
    getRefundMethodsForWallet: (walletId) => client.request({
      endpoint: `/widgets/${config.widgetUUID}/wallets/${walletId}/refundmethods`,
      method: "GET",
    }),
  };
}
