import React from "react";

import { writeStorage, useLocalStorage } from "@rehooks/local-storage";
import PropTypes from "prop-types";

import backends from "../backends";
import I18nContext from "./I18nContext";

/**
 * The context provider of I18nContext
 */
const I18nContextProvider = ({
  backend,
  locale,
  defaultLocale,
  allowedLocales,
  isLocaleLocked,
  files,
  overwrittenLocales,
  localStorageKey,
  children,
  onLocaleChange,
  ...props
}) => {
  const { negotiateLanguages, getString, Translate, Provider } = backends.getBackend(backend);
  // Here are the main states for this context.
  const [localeValue, setLocaleValue] = React.useState(locale);
  const [allowedLocalesValue, setAllowedLocalesValue] = React.useState(allowedLocales);
  const [isLocaleLockedValue, setIsLocaleLockedValue] = React.useState(isLocaleLocked);
  // Here we store the available locales sorted according to the config and the available translation files.
  const [negotiatedLocales, setNegotiatedLocales] = React.useState(null);

  try {
    const [localStorageLocale] = useLocalStorage(localStorageKey);
    if (localStorageLocale && localeValue !== localStorageLocale && allowedLocalesValue.includes(localStorageLocale)) {
      setLocaleValue(localStorageLocale);
    }
  } catch (e) {
    /* eslint-disable no-console */
    console.log("Impossible to use localStorage");
  }

  const setLocaleAndWriteInLocalStorage = React.useCallback(
    // eslint-disable-next-line no-shadow
    (locale) => {
      try {
        setLocaleValue(locale);
        if (localStorageKey) {
          writeStorage(localStorageKey, locale);
        }
      } catch (e) {
        console.error("Impossible to use localStorage");
      }
    },
    [localStorageKey],
  );

  React.useEffect(
    () => {
      if (onLocaleChange) {
        onLocaleChange(localeValue);
      }
    },
    [onLocaleChange, localeValue],
  );

  React.useEffect(
    () => {
      if (allowedLocalesValue && !allowedLocalesValue.includes(localeValue) && negotiatedLocales) {
        if (defaultLocale) {
          setLocaleValue(defaultLocale);
        } else if (negotiatedLocales !== null && negotiatedLocales.length) {
          setLocaleValue(negotiatedLocales[0]);
        } else {
          setLocaleValue("en");
        }
      }
    },
    [allowedLocalesValue, defaultLocale, localeValue, negotiatedLocales, setLocaleValue],
  );

  React.useEffect(
    () => {
      if (negotiateLanguages) {
        const requestedLanguages = localeValue
          ? [localeValue, ...navigator.languages]
          : navigator.languages;
        const negotiatedLanguagesComputed = negotiateLanguages(
          requestedLanguages,
          [...Object.keys(files)],
          { defaultLocale: "en" },
        );
        setNegotiatedLocales(negotiatedLanguagesComputed);
      }
    },
    [negotiateLanguages, files, localeValue],
  );

  React.useEffect(
    () => {
      // Update the html element's lang attribute for in-browser translators (like chrome's).
      // Note here we could use locale value that should be the same as negotiatedLanguages[0].
      if (localeValue) {
        // eslint-disable-next-line prefer-destructuring
        document.documentElement.lang = localeValue;
      }
    },
    [localeValue],
  );

  if (!Provider || !Translate || !negotiateLanguages) {
    return null;
  }

  if (negotiatedLocales === null) {
    return null;
  }

  return (
    <I18nContext.Provider
      value={{
        backend,
        Translate,
        locale: localeValue,
        allowedLocales: allowedLocalesValue,
        isLocaleLocked: isLocaleLockedValue,
        setLocale: setLocaleAndWriteInLocalStorage,
        setAllowedLocalesValue,
        setIsLocaleLockedValue,
        getString,
        negotiatedLocales,
        files,
      }}
    >
      {React.createElement(
        Provider,
        {
          ...props,
          files,
          locales: negotiatedLocales,
          overwrittenLocales,
        },
        children,
      )}
    </I18nContext.Provider>
  );
};

I18nContextProvider.propTypes = {
  /**
   * The backend / lib to load for translation and locales management.
   */
  backend: PropTypes.oneOf(["dummy", "fluent"]).isRequired,
  /**
   * The locale to use for translations in the whole widget.
   */
  locale: PropTypes.string,
  /**
   * The default locale defined by the widget.
   */
  defaultLocale: PropTypes.string,
  /**
   * The list of available locales the user can choose.
   */
  allowedLocales: PropTypes.arrayOf(PropTypes.string),
  /**
   * A state to know whether the locale value cannot be changed
   * (this field can be use as a field to store any custom value, here it's related to the application needs).
   */
  isLocaleLocked: PropTypes.bool,
  /**
   * The object that list and link the language code to the translation file that are supported by the application.
   */
  files: PropTypes.object.isRequired,
  /**
   * The object to overwrite some label keys of the translation files,
   * Object key is the locale and the value is a string that follow the 'ftl' syntax.
   * (some organizers want to customize specific labels).
   */
  overwrittenLocales: PropTypes.object,
  /**
   * The key to use to retrieve and save the config in the local storage. If null, we don't use local storage.
   */
  localStorageKey: PropTypes.string,
  /**
   * A callback function to trigger when the locale change.
   * Take care to wrap this function in a useCallback to avoid rerender.
   */
  onLocaleChange: PropTypes.func,
  /**
   * The components that are wrapped by the provider and that want to be translated.
   */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.object]).isRequired,
};

I18nContextProvider.defaultProps = {
  locale: null,
  defaultLocale: null,
  allowedLocales: ["en"],
  isLocaleLocked: false,
  overwrittenLocales: {},
  localStorageKey: null,
};

export default I18nContextProvider;
